import React, { useState } from "react";
import { Link } from "gatsby";
import { Twitter, Linkedin, Instagram, Menu, X } from "react-feather";

export const Navbar = () => {
  const [navbarState, setNavbarState] = useState("closed");
  const handleToggleNavbar = () => {
    if (navbarState === "closed") {
      setNavbarState("open");
    } else {
      setNavbarState("closed");
    }
  };
  return (
    <>
      <div className="mobile-nav">
        <Link to="/" className="logo logoLarge">
          yuksel tolun
        </Link>
        <Link to="/" className="logo logoSmall">
          ytolun
        </Link>
        <div
          className={`toggleButton ${navbarState}`}
          onClick={handleToggleNavbar}
        >
          <Menu className="expandButton" size={30} />
          <X className="shrinkButton" size={30} />
        </div>
      </div>
      <nav className={`navbar ${navbarState}`}>
        <ul className="main-navigation">
          <Link to="/">hakkımda</Link>
          <Link to="/blog">yazılarım</Link>
          <Link to="/workshops">atölyeler</Link>
        </ul>
        <ul className="social" aria-hidden="true">
          <a href="https://www.linkedin.com/in/ytolun/" title="LinkedIn Profile">
            <Linkedin size={18} />
          </a>
          <a href="https://twitter.com/YTolun" title="Twitter Profile">
            <Twitter size={18} />
          </a>
          <a href="https://www.instagram.com/ytolun/" title="Instagram Profile">
            <Instagram size={18} />
          </a>
        </ul>
      </nav>
    </>
  );
};
