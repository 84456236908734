import React from "react";
import { Link } from "gatsby";
import { Heart } from "react-feather";

export const Footer = () => (
  <footer>
    <div className="container">
      © 2020 by yuksel tolun
      <Heart size={16} />
      <Link to="/privacy">gizlilik politikası</Link>
    </div>
  </footer>
);
